











import { Component, Mixins, Prop } from 'vue-property-decorator'

import { Responsive, UseNonce } from '../../../../../../../../extensions'

/**
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 */
@Component<Divider>({
  name: 'Divider',
  components: {
    UiHeading: () => import(
      /* webpackChunkName: "atoms/UiHeading" */
      '../../../../../../../atoms/UiHeading/UiHeading.vue'
    ),
    UiMarkdown: () => import(
      /* webpackChunkName: "atoms/UiMarkdown" */
      '../../../../../../../atoms/UiMarkdown/UiMarkdown.vue'
    )
  }
})
export class Divider extends Mixins<Responsive, UseNonce>(Responsive, UseNonce) {
  /**
   * The text content to render (with Markdown support (will be parsed to HTML)).
   */
  @Prop({ type: String, required: true })
  public readonly content!: string

  /**
   * Heading's level (from 1 to 6).
   */
  @Prop({ type: Number, required: false, default: 4 })
  public readonly headingLevel!: number

  /**
   * A number that the final element's width will be multiplied by.
   */
  @Prop({ type: Number, required: false, default: 1 })
  private readonly widthMultiplier!: number

  @Prop({ type: String, required: true })
  public readonly htmlId!: string

  /**
   * Determines whether there's a valid content to be rendered.
   */
  public get hasContent (): boolean {
    return typeof this.content === 'string' && this.content.length > 0
  }

  /**
   * Value for the `width` CSS property of the component's root HTML element.
   */
  public get elWidth (): string | undefined {
    if (this.isPhone) {
      return
    }

    let width: number = this.charactersCount / 3

    if (this.charactersCount < 20) {
      width = width * 2
    } else if (this.charactersCount < 30) {
      width = width * 1.5
    } else if (this.charactersCount > 50) {
      width = width * 0.75
    }

    width = width * this.widthMultiplier

    return `${width}ch`
  }

  public get style (): string {
    return `
      #${this.htmlId} {
        width: ${this.elWidth};
        height: ${this.elWidth};
      }
    `
  }

  /**
   * Number of characters in the string under the `content` property.
   */
  private get charactersCount (): number {
    return typeof this.content === 'string' ? this.content.length : 0
  }
}

export default Divider
