// Copyright © 2021 Move Closer

import { AsyncComponent } from 'vue'

import { EntryType } from '../../../Timeline.contracts'

/**
 * Registry that binds the `EntryType` with the applicable Vue component.
 *
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 */
export const entryTypeComponentRegistry: Record<EntryType, AsyncComponent> = {
  [EntryType.Divider]: () => import(
    /* webpackChunkName: "modules/Timeline" */
    './partials/Divider/Divider.vue'
  ),
  [EntryType.Event]: () => import(
    /* webpackChunkName: "modules/Timeline" */
    './partials/Event/Event.vue'
  )
}
