






import { AnyObject } from '@movecloser/front-core'
import { AsyncComponent } from 'vue'
import { Component, Prop, Vue } from 'vue-property-decorator'

import { EntryType } from '../../../Timeline.contracts'

import { entryTypeComponentRegistry } from './Entry.config'

/**
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 */
@Component<Entry>({ name: 'Entry' })
export class Entry extends Vue {
  /**
   * Data to be rendered.
   * Should contain **all of the required props** from the `<Divider>` or `<Event>` partial.
   */
  @Prop({ type: Object, required: true })
  public readonly data!: AnyObject

  /**
   * The type of the entry.
   * Determines the Vue component that will be used to render the entry's data.
   */
  @Prop({ type: String, required: true })
  private readonly type!: EntryType

  @Prop({ type: String, required: true })
  public readonly htmlId!: string

  /**
   * The Vue component that should be used to render the entry's data.
   */
  public get component (): AsyncComponent {
    return entryTypeComponentRegistry[this.type]
  }

  /**
   * Determines whether the rendering component has been successfully found within the `entryTypeComponentRegistry`.
   */
  public get hasComponent (): boolean {
    return typeof this.component !== 'undefined'
  }

  /**
   * Determines whether the component has been provided with the correct `data` prop.
   */
  public get hasData (): boolean {
    return typeof this.data !== 'undefined'
  }
}

export default Entry
