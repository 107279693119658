














import { Component, Prop, Vue } from 'vue-property-decorator'

import { Heading, HeadingLevel } from '../../../../../../../../contracts'

/**
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 */
@Component<Event>({
  name: 'Event',
  components: {
    UiHeading: () => import(
      /* webpackChunkName: "atoms/UiHeading" */
      '../../../../../../../atoms/UiHeading/UiHeading.vue'
    ),
    UiMarkdown: () => import(
      /* webpackChunkName: "atoms/UiMarkdown" */
      '../../../../../../../atoms/UiMarkdown/UiMarkdown.vue'
    )
  }
})
export class Event extends Vue {
  /**
   * Event's body (with Markdown support (will be parsed to HTML)).
   */
  @Prop({ type: String, required: false })
  public readonly body?: string

  /**
   * Event's heading (with Markdown support (will be parsed to HTML)).
   */
  @Prop({ type: Object, required: false })
  public readonly heading?: Heading

  /**
   * Determines whether there's a valid `body` to be rendered.
   */
  public get hasBody (): boolean {
    return typeof this.body === 'string' && this.body.length > 0
  }

  /**
   * Determines whether there's a valid `heading` to be rendered.
   */
  public get hasHeading (): boolean {
    return typeof this.heading === 'object' &&
      typeof this.heading.text === 'string' &&
      this.heading.text.length > 0
  }

  /**
   * Getter for proper heading level
   */
  public get level (): HeadingLevel {
    if (!this.heading || !this.heading.level) {
      return HeadingLevel.H4
    }
    return this.heading.level
  }

  /**
   * Determines whether the component should be render.
   */
  public get shouldRender (): boolean {
    return this.hasBody || this.hasHeading
  }
}

export default Event
